import { Component } from "react";
import ThematicsDropdown from "../../components/ThematicsDropdownComponent.react";
import { Thematic } from "../../types/Thematic";
import uuid from "tiny-uuid4";

/*
  Usage:
  <% props = { thematics: IterateThematicsService.new(@event).tree_sorted, currentThematicIds: @guest_product.thematic_ids, inputName: 'guest_product[thematic_ids][]' } %>
  <div class="react-anchor" data-react-component="ThematicsDropdownErb" data-react-component-props="<%= props.to_json %>"></div>

  Async usage:
  <% props = { fetchThematicsEndpoint: "/api/public/thematics.json", currentThematicIds: [xxx, yyy], inputName: 'guest_product[thematic_ids][]' } %>
  <div class="react-anchor" data-react-component="ThematicsDropdownErb" data-react-component-props="<%= props.to_json %>"></div>
*/

interface Props {
  currentThematicIds: string[];
  thematics?: Thematic[];
  fetchThematicsEndpoint?: string;
  inputName: string;
  placeholder?: string;
  removeInputWhenEmpty?: boolean;
  displayOnlyThematicIds: string[];
  coloredThematics?: boolean;
  limit?: number;
  isDisabled?: boolean;
  doNotFollowHierarchyOnSelect?: boolean;
}

interface State {
  thematics?: Thematic[];
  thematicIds: string[];
}

type ThematicOption = {
  value: string;
  label: string;
}

class ThematicsDropdownErb extends Component<Props, State> {
  uniqueId: string;
  changeThematicsValue(targetId: string, ids: string[]): void {
    if (targetId !== this.uniqueId) return;

    this.setState({ thematicIds: ids });
  }

  constructor(props) {
    super(props);
    [
      "updateThematics", "changeThematicsValue"
    ].forEach(fn => this[fn] = this[fn].bind(this));

    this.uniqueId = uuid();

    this.state = {
      thematicIds: props.currentThematicIds || [],
      thematics: props.thematics
    };
  }

  componentDidMount(): void {
    if (this.props.fetchThematicsEndpoint) {
      this.fetchThematics();
    }

    (window as any).changeThematicsValue = this.changeThematicsValue;
  }

  updateThematics(options: ThematicOption[]): void {
    this.setState({
      thematicIds: options.map(option => option.value)
    });
  }

  fetchThematics(): void {
    fetch(this.props.fetchThematicsEndpoint)
      .then(res => res.json())
      .then(result => this.setState({
        thematics: result
      }));
  }

  isEmpty(): boolean {
    const { thematicIds } = this.state;
    return !thematicIds || thematicIds.length === 0;
  }

  renderHiddenFields(): JSX.Element|JSX.Element[] {
    const { inputName, removeInputWhenEmpty } = this.props;

    if (!removeInputWhenEmpty && this.isEmpty()) {
      return <input type="hidden" id={this.uniqueId} name={ inputName || "thematic_ids[]" }/>;
    }

    return this.state.thematicIds.map(thematicId => {
      return <input type="hidden" id={this.uniqueId} key={ thematicId } value={ thematicId } name={ inputName || "thematic_ids[]" }/>;
    });
  }

  thematics(): Thematic[] {
    const { thematics } = this.state;
    const { displayOnlyThematicIds } = this.props;

    if (!displayOnlyThematicIds || displayOnlyThematicIds.length === 0) {
      return thematics;
    }

    return thematics.filter(t => displayOnlyThematicIds.includes(t._id));
  }

  renderContent(): JSX.Element {
    const { placeholder, coloredThematics, limit, isDisabled, doNotFollowHierarchyOnSelect } = this.props;
    const thematics = this.thematics();
    if (thematics.length === 0) return null;

    return <ThematicsDropdown
      thematics={thematics}
      value={this.state.thematicIds}
      onChange={this.updateThematics}
      followHierarchyOnSelect={!doNotFollowHierarchyOnSelect}
      placeholder={placeholder}
      coloredThematics={coloredThematics}
      limit={limit}
      isDisabled={isDisabled}
    />;
  }

  render(): JSX.Element {
    if (!this.state.thematics) return null;

    return <>
      {this.renderContent()}
      {this.renderHiddenFields()}
    </>;
  }
}

export default ThematicsDropdownErb;
