import { Component } from "react";
import CreatableSelect from "react-select/creatable";
import Select, { components, defaultTheme } from "react-select";
import { thematicOptions, toggleThematicHandler, thematicChildrenIds } from "./../utils/thematics";
import { multiValueRemoveStyle } from "../constants/Style";

const SUB_THEMATIC_INDENTATION = 20;
const SELECTED_COLOR = defaultTheme.colors.primary;
const DISABLED_COLOR = "#EFEFEF";

const ThematicMultiValueContainer = (props) => {
  const innerProps = { ...props.innerProps, className: props.innerProps.className.concat(" badge rounded-pill") };
  const newProps = { ...props, innerProps };
  return <components.MultiValueContainer {...newProps} />;
};

const ThematicMultiValueLabel = (props) => {
  return <span>{props.data.label}</span>;
};

const customStyles = {
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? SELECTED_COLOR : (state.isDisabled ? DISABLED_COLOR : base.backgroundColor),
    display: (state.data.show || state.data.__isNew__) ? "flex" : "none !important"
  }),
  multiValue: (base, state) => ({
    ...base,
    backgroundColor: state.data.color,
    fontSize: "100%"
  }),
  multiValueRemove: (base) => ({
    ...base,
    ...multiValueRemoveStyle
  })
};

export default class ThematicsDropdownComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearching: false,
      toggledThematicIds: []
    };

    [
      "onChange",
      "onInputChange",
      "renderThematicOption"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  i18n(key, options = {}) {
    return I18n.t(`react.reports.thematics_dropdown.${key}`, options);
  }

  renderThematicOption(option) {
    if (option.__isNew__) {
      return option.label;
    }
    const { isSearching, toggledThematicIds } = this.state;
    const toggled = toggledThematicIds.includes(option.value);

    return <>
      <span
        className="badge rounded-pill"
        style={{
          backgroundColor: option.color,
          marginLeft: `calc(${option.depthLevel} * ${SUB_THEMATIC_INDENTATION}px)`,
          float: "none"
        }}
      >
        {option.label}
      </span>
      {!isSearching && option.hasChildren && (
        <button className="btn btn-link btn-sm pt-0 pb-0" onClick={toggleThematicHandler(this.props.thematics, option.value, toggledThematicIds, (values) => this.setState({ toggledThematicIds: values }))}>
          <i className={`fa-regular fa-chevron-${toggled ? "up" : "down"}`}></i>
        </button>
      )}
    </>;
  }

  // onChange we take the options and see which one was removed
  findRemovedThematic(nextOptions) {
    const { value, thematics } = this.props;
    const changedIds = nextOptions.map(option => option.value);
    const removedThematicId = value.filter(thematicId => !changedIds.includes(thematicId))[0];
    return thematics.find(t => t._id === removedThematicId);
  }

  // onChange we take the options and see which one was added
  findAddedThematic(nextOptions) {
    const { value, thematics } = this.props;
    const addedOptions = nextOptions.filter(option => !value.includes(option.value))[0];
    return thematics.find(t => t._id === addedOptions.value);
  }

  // return the ids of the parents of the thematic
  thematicParentIds(thematic) {
    if (!thematic.parent_thematic_id) {
      return [];
    }

    const { thematics } = this.props;
    const parent = thematics.find(t => t._id === thematic.parent_thematic_id);
    return [parent._id].concat(this.thematicParentIds(parent));
  }

  // onChange add / remove parents / children thematics
  includeHierarchicalChanges(options) {
    const { value } = this.props;

    if (value.length === options.length || options.length === 0) {
      // nothing to do
      return options;
    }

    if (value.length > options.length) {
      // a thematic was removed, we should remove its children as well
      const removed = this.findRemovedThematic(options);
      const otherIdsToRemove = thematicChildrenIds(this.props.thematics, removed);
      return options.filter(option => !otherIdsToRemove.includes(option.value));
    }

    // a thematic was added, we should add its parents as well
    const added = this.findAddedThematic(options);
    let otherIdsToAdd = this.thematicParentIds(added);
    let optionIds = options.map(option => option.value);
    otherIdsToAdd = otherIdsToAdd.filter(id => !optionIds.includes(id));
    const otherOptions = this.options.filter(option => otherIdsToAdd.includes(option.value));
    return [...options, ...otherOptions];
  }

  onChange(options, event) {
    const { followHierarchyOnSelect, onChange } = this.props;
    const realOptions = followHierarchyOnSelect ? this.includeHierarchicalChanges(options) : options;
    onChange(realOptions, event);
  }

  onInputChange(inputValue) {
    this.setState({ isSearching: inputValue.length > 0 });
  }

  render() {
    const { toggledThematicIds } = this.state;
    const { value, onCreate, disableClearAll, placeholder, limit, isDisabled, menuPlacement } = this.props;
    this.options = thematicOptions(this.props.thematics, this.props.coloredThematics, toggledThematicIds, this.state.isSearching);
    const selectedThematics = this.options.filter((thematic) => value.includes(thematic.value));

    const props = {
      className: "react-select",
      classNamePrefix: "react-select",
      isMulti: true,
      closeMenuOnSelect: false,
      options: this.options,
      onChange: this.onChange,
      value: selectedThematics,
      formatOptionLabel: this.renderThematicOption,
      components: {
        MultiValueContainer: ThematicMultiValueContainer,
        MultiValueLabel: ThematicMultiValueLabel
      },
      styles: customStyles,
      hideSelectedOptions: false,
      onInputChange: this.onInputChange,
      isClearable: !disableClearAll,
      isDisabled,
      menuPlacement,
      isOptionDisabled: (option, selectValue) => {
        const limitReached = limit && limit !== -1 && selectValue && selectValue.length >= limit;
        return limitReached && selectValue.indexOf(option) === -1;
      }
    };

    return (onCreate) ? (
      <CreatableSelect
        {...props}
        isClearable={true}
        onCreateOption={onCreate}
        placeholder={placeholder || this.i18n("create_select_placeholder")}
        noOptionsMessage={() => this.i18n("enter_first_thematic")}
        formatCreateLabel={(label) => this.i18n("create_thematic", { label: label })}
        blurInputOnSelect={false}
      />
    ) : (
      <Select
        {...props}
        placeholder={placeholder || this.i18n("select_placeholder")}
        noOptionsMessage={() => this.i18n("no_thematics")}
        blurInputOnSelect={false}
        menuPlacement={menuPlacement || "auto"}
      />
    );
  }
}
