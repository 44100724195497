export const defaultModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1040,
    overflowY: "auto"
  },
  content: {
    position: "initial",
    width: "800px",
    maxWidth: "90%",
    margin: "5% auto",
    padding: "0",
    borderRadius: "8px"
  }
};

export const defaultModalSmallStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1040,
    overflowY: "auto"
  },
  content: {
    position: "initial",
    width: "600px",
    maxWidth: "90%",
    margin: "5% auto",
    padding: "0",
    borderRadius: "8px"
  }
};

export const defaultModalBigStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1040,
    overflowY: "auto"
  },
  content: {
    position: "initial",
    width: "1110px",
    maxWidth: "90%",
    margin: "5% auto",
    padding: "0",
    borderRadius: "8px"
  }
};

export const contactRowStyle = {
  borderBottom: "1px solid #eeeeee",
  marginBottom: "15px",
  paddingBottom: "5px"
};

export const multiValueRemoveStyle = {
  paddingLeft: "7px",
  paddingRight: 0,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "inherit",
    color: "inherit"
  }
};
