import Features from "../../config/feature_set.yml";

function buildFeaturesList(hash, currentList = {}, prefix = null) {
  Object.entries(hash).map(([featureKey, value]) => {
    const newKey = prefix ? `${prefix}.${featureKey}` : featureKey;
    const features = value && value.features;

    if (value) {
      value["key"] = newKey;
    } else {
      value = { key: newKey };
    }
    currentList[newKey] = value;
    if (features) {
      buildFeaturesList(features, currentList, newKey);
    }
  });
  return currentList;
}

function buildFeatureDependents(features) {
  let dependents = {};

  Object.entries(features).forEach(([key, metadata]) => {
    if (!metadata.dependencies) return;

    metadata.dependencies.forEach(dependencyKey => {
      if (!dependents[dependencyKey]) {
        dependents[dependencyKey] = [];
      }

      dependents[dependencyKey].push(key);
    });
  });

  return dependents;
}

export function features() {
  const features = Features || {};
  return Object.entries(features[process.env.NODE_ENV] || {}).reduce((acc, [key, value]) => {
    if (key === "<<") { // yaml loader does not support the merge operator
      return { ...acc, ...value };
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export const FEATURES = features();
export const FEATURE_BY_KEY = buildFeaturesList(features());
export const FEATURES_DEPENDENTS = buildFeatureDependents(FEATURE_BY_KEY);

export const FEATURE_KEYS = Object.keys(FEATURE_BY_KEY).reduce((acc, featureKey) => {
  if ((featureKey.match(/\./g) || []).length < 2) {
    return acc;
  }

  const key = featureKey.replace(/\./g, "_").toUpperCase();
  acc[key] = featureKey;
  return acc;
}, {});

export const FEATURE_SHORT_KEYS = Object.keys(FEATURE_BY_KEY).reduce((acc, featureKey) => {
  const keyParts = featureKey.split(".");
  const shortKey = keyParts[keyParts.length - 1].toUpperCase();
  acc[shortKey] = featureKey;
  return acc;
}, {});

export const FEATURES_ENABLED_BY_DEFAULT = Object.entries(FEATURE_BY_KEY).reduce((acc, [featureKey, featureMetadata]) => {
  if (featureMetadata && featureMetadata.enabled_by_default) acc.push(featureKey);
  return acc;
}, []);

module.exports = { ...FEATURE_KEYS, ...FEATURE_SHORT_KEYS, FEATURE_KEYS, FEATURES, FEATURE_BY_KEY, FEATURES_DEPENDENTS, FEATURES_ENABLED_BY_DEFAULT };
